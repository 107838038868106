<template>
  <div class="relative h-32">
    <div class="bottom-div absolute inset-0 rounded-xl shadow-md"></div>
    <div
      class="top-div absolute inset-0 rounded-xl px-6 pt-4 pb-4 mb-4 h-32 z-10"
    >
      <div class="flex justify-between items-center mb-2 text-sm">
        <h3 class="text-sm">
          Status:
          <span class="text-sm" :class="statusFlashClass">{{
            formattedStatus
          }}</span>
        </h3>
        <h3 class="text-sm w-60 flex justify-end">
          Best Difficulty:
          <span class="ml-2 text-sm" :class="flashClass">{{
            currentBestDifficulty
          }}</span>
        </h3>
      </div>

      <div class="relative bg-blue-200 rounded-xl h-8 mb-2">
        <div
          class="absolute inset-0 bg-green-500 rounded-xl transition-all duration-300 ease-in-out"
          :style="{ width: `${progressPercentage}%` }"
        ></div>
        <div class="absolute inset-0 flex items-center justify-center text-sm">
          {{ progressText }}
        </div>
      </div>

      <div class="flex justify-between text-sm">
        <p class="hidden md:block text-sm mt-1">
          Solutions: {{ noncesChecked }} / {{ totalNonces }}
        </p>
        <p class="hidden md:block text-sm mt-1">
          Nonces / sec: {{ noncesPerSecond }}
        </p>
        <p class="hidden md:block text-sm mt-1">
          Challenge: {{ abbreviatedChallenge }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, defineProps } from "vue";

const props = defineProps({
  status: String,
  noncesChecked: Number,
  noncesPerSecond: Number,
  challenge: String,
  nonceRangeStart: Number,
  nonceRangeEnd: Number,
  currentBestDifficulty: Number,
  totalSolvingTime: Number,
  timeRemaining: Number,
  timeElapsed: Number,
});

const flashClass = ref("");
const statusFlashClass = ref("");

const triggerFlash = () => {
  flashClass.value = "text-secondary";
  setTimeout(() => {
    flashClass.value = "";
  }, 2500);
};

const triggerStatusFlash = () => {
  statusFlashClass.value = "text-secondary";
  setTimeout(() => {
    statusFlashClass.value = "";
  }, 500);
};

watch(
  () => props.currentBestDifficulty,
  (newDifficulty, oldDifficulty) => {
    if (newDifficulty !== oldDifficulty) triggerFlash();
  }
);

watch(
  () => props.status,
  (newStatus, oldStatus) => {
    if (newStatus !== oldStatus) triggerStatusFlash();
  }
);

const totalNonces = computed(() => {
  const total = props.nonceRangeEnd - props.nonceRangeStart;
  return total === 0 ? 0 : total + 1;
});

const progressPercentage = computed(() => {
  if (props.totalSolvingTime === 0) return 0;
  const percentage = (props.timeElapsed / props.totalSolvingTime) * 100;
  return Math.min(Math.ceil(percentage), 100);
});

const progressText = computed(() => {
  if (props.status.includes("Waiting") || props.totalSolvingTime <= 3) {
    return "Waiting...";
  }
  return `${progressPercentage.value.toFixed(2)}%`;
});

const abbreviatedChallenge = computed(() => {
  if (props.challenge.length <= 10) return props.challenge;
  return `${props.challenge.slice(0, 5)}...${props.challenge.slice(-5)}`;
});

const formattedStatus = computed(() => {
  if (props.status.includes("Waiting") || props.totalSolvingTime <= 3) {
    return "Waiting...";
  }
  return props.status;
});
</script>

<style scoped>
.bottom-div {
  background: linear-gradient(
    135deg,
    rgba(173, 216, 230, 0.5) 0%,
    rgba(255, 255, 255, 0.7) 30%,
    rgba(231, 218, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.4) 80%,
    rgba(176, 224, 230, 0.5) 100%
  );
  background-size: 200% 200%;
  animation: gradientAnimation 16s ease infinite;
  height: 100%;
  width: 100%;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
