<template>
  <div class="component-container">
    <div class="headline-container">
      <h1 class="text-6xl font-bold text-center mt-8 md:mt-28">
        Mine For The Ocean
      </h1>
      <p class="text-2xl text-center mt-6 md:mt-8 text-slate-600">
        Donate your excess compute power and support the:
        <span class="block mt-8 md:mt-12">
          <a
            href="https://thegivingblock.com/donate/ocean-defenders-alliance/"
            target="_blank"
            rel="noopener nofollow"
            class="bg-blue-400 hover:bg-blue-300 px-4 py-2 md:px-8 md:py-3 rounded-xl text-slate-200 hover:text-slate-100 text-lg md:text-2xl"
            >Ocean Defenders Alliance</a
          ></span
        >
      </p>
    </div>
    <div class="solver-container max-w-2xl mx-auto mt-8 md:mt-16 w-full px-4">
      <div class="w-full">
        <StatusBox
          :status="status"
          :nonces-checked="parseInt(noncesChecked)"
          :nonces-per-second="parseFloat(noncesPerSecond)"
          :challenge="currentChallenge"
          :nonce-range-start="nonceRangeStart"
          :nonce-range-end="nonceRangeEnd"
          :current-best-difficulty="currentBestDifficulty"
          :time-elapsed="timeElapsed"
          :total-solving-time="totalSolvingTime"
          :time-remaining="timeRemaining"
        />
      </div>
      <div class="w-full mt-4">
        <button
          @click="toggleSolving"
          class="w-full px-4 py-2 text-base-content rounded-xl transition duration-300"
          :class="{
            'bg-blue-600 hover:bg-blue-500 text-white': !isSolving,
            'bg-gray-400 hover:bg-gray-300': isSolving,
          }"
        >
          {{ isSolving ? "Stop Solving" : "Start Solving" }}
        </button>
      </div>
    </div>
    <div
      class="explanation-container mt-8 md:mt-16 max-w-2xl mx-auto px-4 hidden md:block"
    >
      <div class="explanation-card px-6 py-3 rounded-xl">
        <h2 class="text-4xl mt-3 font-bold text-center text-blue-400">
          How It Works
        </h2>
        <p class="text-xl text-center text-slate-800 mb-3">
          Mine For The Ocean is a platform that allows you to donate your excess
          compute power to support the Ocean Defenders Alliance. By solving
          <a
            href="https://cashcaptcha.com"
            target="_blank"
            rel="noopener"
            class="text-blue-600 hover:text-blue-500 text-xl"
            >cashcaptcha.com</a
          >
          puzzles, you can earn rewards that are donated to the alliance.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import StatusBox from "./StatusBox.vue";
import { Solver } from "cash-captcha";

const status = ref("Idle");
const noncesChecked = ref(0);
const noncesPerSecond = ref(0);
const currentChallenge = ref("");
const nonceRangeStart = ref(0);
const nonceRangeEnd = ref(0);
const currentBestDifficulty = ref(0);
const timeElapsed = ref(0);
const totalSolvingTime = ref(0);
const timeRemaining = ref(0);
const isSolving = ref(false);

let solver = null;

onMounted(() => {
  solver = new Solver("9ef12a91-52fe-4d3c-a7b1-fa58bb92ef23");
  window.addEventListener("workerUpdate", handleWorkerUpdate);
  window.addEventListener("solvingStatus", handleSolverStatus);
});

const handleWorkerUpdate = (event) => {
  const data = event.detail;
  if (data.bestDifficulty !== undefined) {
    currentBestDifficulty.value = data.bestDifficulty;
  }
  if (data.type === "status") {
    status.value = data.status;
    noncesChecked.value = data.noncesChecked ?? noncesChecked.value;
    noncesPerSecond.value = data.noncesPerSecond ?? noncesPerSecond.value;
    currentChallenge.value = data.challenge ?? currentChallenge.value;
    nonceRangeStart.value = data.nonceStart ?? nonceRangeStart.value;
    nonceRangeEnd.value = data.nonceEnd ?? nonceRangeEnd.value;
    timeElapsed.value = data.timeElapsed ?? timeElapsed.value;
    totalSolvingTime.value = data.totalSolvingTime ?? totalSolvingTime.value;
    timeRemaining.value = data.timeRemaining ?? timeRemaining.value;
  }
};

const handleSolverStatus = (event) => {
  status.value = event.detail;
};

const toggleSolving = () => {
  if (isSolving.value) {
    solver.stop();
    status.value = "Idle";
    noncesChecked.value = 0;
    noncesPerSecond.value = 0;
    currentBestDifficulty.value = 0;
    currentChallenge.value = "";
    nonceRangeStart.value = 0;
    nonceRangeEnd.value = 0;
  } else {
    solver.start();
  }
  isSolving.value = !isSolving.value;
};

onUnmounted(() => {
  window.removeEventListener("workerUpdate", handleWorkerUpdate);
  window.removeEventListener("solvingStatus", handleSolverStatus);
  if (solver) {
    solver.stop();
  }
});
</script>

<style scoped>
.component-container {
  min-height: 100vh;
}

button {
  z-index: 1;
}

.explanation-card {
  background: linear-gradient(
    135deg,
    rgba(173, 216, 230, 0.5) 0%,
    rgba(255, 255, 255, 0.7) 30%,
    rgba(231, 218, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.4) 80%,
    rgba(176, 224, 230, 0.5) 100%
  );
  background-size: 200% 200%;
  animation: gradientAnimation 16s ease infinite;
  height: 100%;
  width: 100%;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
